import React from "react"
import Layout from "../components/layout"
import { Card, Row, Col, Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
const transformativeWay = () => {
  return (
    <Layout>
      <Helmet>
        <title>The Transformative Way</title>
      </Helmet>
      <h2>The Transformative Way</h2>
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            style={{
              paddingLeft: "0px",
              height: "60%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <StaticImage
              src={"../images/tranformativeWay.png"}
              alt={"The Transformative Way"}
              height="60%"
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card.Text
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              href="https://www.amazon.co.uk/Transformative-Way-Creative-Writing-Resources/dp/B0DHV3N6KW/ref=sr_1_1?crid=39ZSTKX2BI8IU&dib=eyJ2IjoiMSJ9.050E997tsEYmJoRi_CqEdA.yulP8ufg0VKFyNYuGZ_VFsVDkICrYAdBz-7RfsaYu7Q&dib_tag=se&keywords=Pamela+Mary+Brown+the+transformative+way&qid=1727803145&sprefix=pamela+mary+brown+the+transformative+way%2Caps%2C69&sr=8-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`The Transformative Way: Creative Prison Writing with Resources `}
            </a>
          </Card.Text>
        </Row>
        <Row>
          <Card.Text style={{ marginTop: "20px" }}>
            <i>
              For workshop information: prison facilitator, prisoners, addiction
              centres, secure facilities, mental health, psychiatric
              institutions and hospitals please see contact details.
            </i>
          </Card.Text>

          <Card.Text style={{ marginTop: "10px" }}>
            <i>‘The Transformative Way’</i> presents 52 ‘tried and tested’
            workshop-resources based on years of experience by Pamela Brown,
            Pearson Award winning Facilitator and Lecturer in Creative Writing.
            The resources are proven to empower prison-writers throughout the
            textbook, outlining the dynamic practically of{" "}
            <i> ‘The Transformative Way’.</i> The dynamic maximizes the
            ‘journey’ for the prison writer entering the workshop environment,
            combatting literacy issues (if any) and progressing while receiving
            validation. The ‘creative journey’ is achieved through gaining
            independence as a creative thinker and writer. Shadowing the
            textbook, Brown relates, ‘are those whom I have witnessed making a
            successful rehabilitation with family, society, relationships and
            the workplace: this is breakout, breakthrough and real release as
            their earlier previous release into creativity.’
          </Card.Text>
          <Card.Text style={{ marginTop: "10px" }}>
            The 52 resources are grounded in Brown’s vast background projected
            onto the genre of prison literature—a tradition that encompasses
            centuries of writing. The textbook includes samples and examples
            from every genre with discussion of classics such as{" "}
            <i>
              ‘The Ballad of Reading Gaol’, ‘Lifer’, ‘Borstal Boy’, ‘Pain of
              Confinement: Prison Diaries’{" "}
            </i>
            and American poet, Jimmy Santiago Baca’s <i>‘A Place to Stand’.</i>
          </Card.Text>

          <Card.Text style={{ marginTop: "10px" }}>
            The textbook makes the case for wider acknowledgement of the prison
            writing and literature genre within the academy related to
            psychology, law, criminology and ‘post trauma individuation’. The
            psychological aspect indicates directions for development during
            what is <i> ‘an emergency situation’</i> as the participant in
            workshops moves transitionally into the post traumatic state and
            their writing comes to fruition. The process can bring about post
            traumatic resolutions, visible for the writer through what is
            produced. Crucially, creative writing has delved into the conscious
            and subconscious and sign posts <i>The Transformative Way. </i>
            <i>‘Individuation’ </i> as explored by modern psychology,
            psychoanalysis, and group psychotherapeutic therapy is mirrored in
            the collective workshop process. In this zone, the prisoner is not
            solitary as when in the prison lockup. Prison-writing facilitators
            will identify with Brown’s background and engagement within creative
            writing as artistic discipline demanding education, talent,
            achievements, experience, and depths of knowledge vital to directing
            each workshop event, incident and outcome.
          </Card.Text>

          <Card.Text style={{ marginTop: "10px" }}>
            <b> Pamela Mary Brown</b> poet, author, performer,
            Writer-in-Residence HMP Magilligan; Editor Time In magazine;
            Co-Editor All In magazine; (former) Creative Writing Tutor-Assessor,
            North West Regional College; MA in Creative Writing (distinction).,
            Open University; BA (Hons.)., English Literature & Creative Writing,
            Teesside University; Extra-Mural Studies/Community Drama (with
            commendation), University of Ulster; Media Studies, Foyle Arts
            Centre, Derry. Pearson Silver Award for Further Education Lecturer
            of the Year (2023).
          </Card.Text>
        </Row>
      </Container>
    </Layout>
  )
}

export default transformativeWay
